import { Fragment, useEffect, useState } from "react";
import moment from "moment";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";

const months = {
    0: "January",
    1: "Feburary",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
};

const WeeklyCalendar = ({ day, setDay, getDailySchedule, today, setToday }) => {
    const [days, setDays] = useState([]);
    const [run, setRun] = useState(false);

    useEffect(() => {
        setDays([]);
        setDay(`${today.getDay()}, ${today.getDate()}`);
        const getDays = () => {
            for (let i = 0; i < 7; i++) {
                setDays((oldDays) => [
                    ...oldDays,
                    today.getDay() === i
                        ? today.getDate()
                        : new Date(
                            today.getTime() +
                            (i === 0 ? today.getDay() * -1 : (today.getDay() - i) * -1) *
                            24 *
                            60 *
                            60 *
                            1000
                        ).getDate(),
                ]);
            }
        };

        getDays();
        getDailySchedule(`${today.getDay()}, ${today.getDate()}`);
        //eslint-disable-next-line
    }, [run]);

    const handleChange = (event, newValue) => {
        setDay(newValue);
        getDailySchedule(newValue);
    };

    const onLeftClick = (event) => {
        setToday(new Date(moment(today).subtract(7, 'day')));
        setRun(!run);
    }

    const onRightClick = (event) => {
        setToday(new Date(moment(today).add(7, 'day')));
        setRun(!run);
    }

    const query = {
        height: "85px",
        overflow: "auto",
        '@media (max-width: 600px)': {
            minWidth: '40px',
            paddingLeft: "0px",
            paddingRight: "0px"
        }
    }

    return (
        <>
            <Stack direction="row" justifyContent="center">
                <Typography variant="h6">
                    <b>
                        {months[today.getMonth()]} {today.getFullYear()}
                    </b>
                </Typography>
            </Stack>
            <Box display="flex" justifyContent="center" alignItems="center" >


                <Button variant="outlined" sx={query} onClick={onLeftClick}>&lt;</Button>
                <Tabs
                    value={day}
                    onChange={handleChange}
                    scrollButtons={false}
                    variant="scrollable"

                >
                    <Tab
                        label={
                            <Fragment>
                                <span
                                    style={{
                                        fontSize: 17,
                                        color: today.getDay() === 0 ? "black" : "",
                                        fontWeight: today.getDay() === 0 ? "bold" : "regular",
                                    }}
                                >
                                    SUN
                                </span>
                                <br />
                                <span
                                    style={{
                                        fontSize: 20,
                                        color: today.getDay() === 0 ? "black" : "",
                                        fontWeight: today.getDay() === 0 ? "bold" : "regular",
                                    }}
                                >
                                    {days[0]}
                                </span>
                            </Fragment>
                        }
                        value={`0, ${days[0]}`}
                    />
                    <Tab
                        label={
                            <Fragment>
                                <span
                                    style={{
                                        fontSize: 17,
                                        color: today.getDay() === 1 ? "black" : "",
                                        fontWeight: today.getDay() === 1 ? "bold" : "regular",
                                    }}
                                >
                                    MON
                                </span>
                                <br />
                                <span
                                    style={{
                                        fontSize: 20,
                                        color: today.getDay() === 1 ? "black" : "",
                                        fontWeight: today.getDay() === 1 ? "bold" : "regular",
                                    }}
                                >
                                    {days[1]}
                                </span>
                            </Fragment>
                        }
                        value={`1, ${days[1]}`}
                    />
                    <Tab
                        label={
                            <Fragment>
                                <span
                                    style={{
                                        fontSize: 17,
                                        color: today.getDay() === 2 ? "black" : "",
                                        fontWeight: today.getDay() === 2 ? "bold" : "regular",
                                    }}
                                >
                                    TUE
                                </span>
                                <br />
                                <span
                                    style={{
                                        fontSize: 20,
                                        color: today.getDay() === 2 ? "black" : "",
                                        fontWeight: today.getDay() === 2 ? "bold" : "regular",
                                    }}
                                >
                                    {days[2]}
                                </span>
                            </Fragment>
                        }
                        value={`2, ${days[2]}`}
                    />
                    <Tab
                        label={
                            <Fragment>
                                <span
                                    style={{
                                        fontSize: 17,
                                        color: today.getDay() === 3 ? "black" : "",
                                        fontWeight: today.getDay() === 3 ? "bold" : "regular",
                                    }}
                                >
                                    WED
                                </span>
                                <br />
                                <span
                                    style={{
                                        fontSize: 20,
                                        color: today.getDay() === 3 ? "black" : "",
                                        fontWeight: today.getDay() === 3 ? "bold" : "regular",
                                    }}
                                >
                                    {days[3]}
                                </span>
                            </Fragment>
                        }
                        value={`3, ${days[3]}`}
                    />
                    <Tab
                        label={
                            <Fragment>
                                <span
                                    style={{
                                        fontSize: 17,
                                        color: today.getDay() === 4 ? "black" : "",
                                        fontWeight: today.getDay() === 4 ? "bold" : "regular",
                                    }}
                                >
                                    THU
                                </span>
                                <br />
                                <span
                                    style={{
                                        fontSize: 20,
                                        color: today.getDay() === 4 ? "black" : "",
                                        fontWeight: today.getDay() === 4 ? "bold" : "regular",
                                    }}
                                >
                                    {days[4]}
                                </span>
                            </Fragment>
                        }
                        value={`4, ${days[4]}`}
                    />
                    <Tab
                        label={
                            <Fragment>
                                <span
                                    style={{
                                        fontSize: 17,
                                        color: today.getDay() === 5 ? "black" : "",
                                        fontWeight: today.getDay() === 5 ? "bold" : "regular",
                                    }}
                                >
                                    FRI
                                </span>
                                <br />
                                <span
                                    style={{
                                        fontSize: 20,
                                        color: today.getDay() === 5 ? "black" : "",
                                        fontWeight: today.getDay() === 5 ? "bold" : "regular",
                                    }}
                                >
                                    {days[5]}
                                </span>
                            </Fragment>
                        }
                        value={`5, ${days[5]}`}
                    />
                    <Tab
                        label={
                            <Fragment>
                                <span
                                    style={{
                                        fontSize: 17,
                                        color: today.getDay() === 6 ? "black" : "",
                                        fontWeight: today.getDay() === 6 ? "bold" : "regular",
                                    }}
                                >
                                    SAT
                                </span>
                                <br />
                                <span
                                    style={{
                                        fontSize: 20,
                                        color: today.getDay() === 6 ? "black" : "",
                                        fontWeight: today.getDay() === 6 ? "bold" : "regular",
                                    }}
                                >
                                    {days[6]}
                                </span>
                            </Fragment>
                        }
                        value={`6, ${days[6]}`}
                    />
                </Tabs>
                <Button variant="outlined" sx={query} onClick={onRightClick}>&gt;</Button>
            </Box>
        </>
    );
};

export default WeeklyCalendar;
