import { useState } from "react";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import SortTable from "./SortTable";

const initalValues = {
    startDate: moment().subtract(1, 'months').format("yyyy-MM-DD"),
    endDate: moment().format("yyyy-MM-DD")
};

const IncomeSummary = () => {
    const [values, setValues] = useState(initalValues);
    const [clicked, setClicked] = useState(false);

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
        setClicked(!clicked);
    };

    const container = {
        '@media (max-width: 780px)': {
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }

    return (
        <Container sx={container}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id="startDate"
                        label="Starting Date"
                        name="startDate"
                        type="date"
                        value={values.startDate}
                        onChange={onChange}
                        InputLabelProps={{ shrink: true }}
                    ></TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id="endDate"
                        label="Ending Date"
                        name="endDate"
                        type="date"
                        value={values.endDate}
                        onChange={onChange}
                        InputLabelProps={{ shrink: true }}
                    ></TextField>
                </Grid>
            </Grid>
            <br></br>
            <SortTable headRow={[
                {
                    id: 'name',
                    numeric: false,
                    disablePadding: false,
                    label: 'Student name',
                },
                {
                    id: 'email',
                    numeric: false,
                    disablePadding: false,
                    label: 'Student email',
                },
                {
                    id: 'date',
                    numeric: false,
                    disablePadding: false,
                    label: 'Date',
                },
                {
                    id: 'grandTotal',
                    numeric: true,
                    disablePadding: false,
                    label: 'Grand total ($)',
                },
                {
                    id: 'lessons',
                    numeric: true,
                    disablePadding: false,
                    label: 'Lessons',
                },
                {
                    id: 'lessonPrice',
                    numeric: true,
                    disablePadding: false,
                    label: 'Lesson price ($)',
                },
                {
                    id: 'rentalInstrument',
                    numeric: false,
                    disablePadding: false,
                    label: 'Rental instrument',
                },
                {
                    id: 'rentalFee',
                    numeric: true,
                    disablePadding: false,
                    label: 'Rental fee ($)',
                },
                {
                    id: 'specialDescription',
                    numeric: false,
                    disablePadding: false,
                    label: 'Special description',
                },
                {
                    id: 'specialFee',
                    numeric: false,
                    disablePadding: false,
                    label: 'Special fee',
                }
            ]} values={values} clicked={clicked}></SortTable>
        </Container>
    )
}

export default IncomeSummary