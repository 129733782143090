import { useState, Fragment, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import useAuth from "../../Hooks/useAuth";
import useRefreshToken from "../../Hooks/useRefreshToken";

const CollapsibleTable = ({ clicked, values }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [loading, setLoading] = useState(true);
    const [historyLoading, setHistoryLoad] = useState(true);
    const [data, setData] = useState([]);
    const [historys, setHistory] = useState([]);
    const [rows, setRows] = useState([]);
    const { auth, access } = useAuth();
    const refresh = useRefreshToken();
    // const [page, setPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => {
        setLoading(true);
        const fetchHistoryData = async () => {
            var refreshed;
            try {
                try {
                    await access(auth.accessToken);
                } catch (err) {
                    refreshed = await refresh();
                } finally {
                    const res = await axios.post(
                        "https://api.suzukitree.com/fetchStudents",
                        {
                            userId: refreshed ? refreshed.userId : auth.userId
                        },
                        {
                            headers: {
                                "Content-type": "application/json",
                                Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken}`,
                            },
                        }
                    );
                    setData(res.data);
                }
            } catch (err) {
                if (err.response.data.message) {
                    setData(err.response.data.message);
                } else {
                    setData(
                        "Something went wrong while to fetch that students' data please refresh the page to try again"
                    );
                }
            }
        }

        fetchHistoryData();

        // eslint-disable-next-line
    }, [clicked]);

    useEffect(() => {
        if (data) {
            setHistoryLoad(true);
            const fetchLessonTransactions = async () => {
                var refreshed;
                try {
                    try {
                        await access(auth.accessToken);
                    } catch (err) {
                        refreshed = await refresh();
                    } finally {
                        const res = await axios.post(
                            "https://api.suzukitree.com/fetchLessons",
                            {
                                userId: refreshed ? refreshed.userId : auth.userId,
                                startDate: values.startDate,
                                endDate: values.endDate
                            },
                            {
                                headers: {
                                    "Content-type": "application/json",
                                    Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken}`,
                                },
                            }
                        );
                        setHistory(res.data);
                        setHistoryLoad(false);
                    }
                } catch (err) {
                    console.log(err);
                    if (err.response.data.message) {
                        setHistory(err.response.data.message);
                    } else {
                        setHistory(
                            "Something went wrong while to fetch that students' lesson transaction data please refresh the page to try again"
                        );
                    }
                }
            }
            fetchLessonTransactions();
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        setRows([]);
        if (!historyLoading && typeof data === "object") {
            data.map((row) =>
                setRows(rows => ([...rows, { name: row.name, email: row.email, lessonDay: row.lessonDays, history: historys.filter(history => history.studentId === row._id) }]))
            );
        }
        setLoading(false);

        // eslint-disable-next-line
    }, [historyLoading]);

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        array = Object.values(array);
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Student name',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            id: 'lessonDays',
            numeric: false,
            disablePadding: false,
            label: 'Lesson day',
        }
    ];

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired
    };

    const EnhancedTableToolbar = () => {
        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Lessons
                </Typography>
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        );
    };

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = useState(false);

        //eslint-disable-next-line
        return !loading || typeof data !== "array" ? (
            <Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.name}
                    </TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.lessonDay}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Lesson transactions
                                </Typography>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell align="right">Lessons left</TableCell>
                                            <TableCell align="right">Lesson transaction</TableCell>
                                            <TableCell align="right">Lesson transaction type</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.history?.length > 0 ? row.history.map((historyRow) => (
                                            <TableRow key={historyRow._id}>
                                                <TableCell component="th" scope="row">
                                                    {moment(historyRow.date).format("MMMM Do YYYY, h:mm a")}
                                                </TableCell>
                                                <TableCell align="right">{historyRow.lessons}</TableCell>
                                                <TableCell align="right">{historyRow.lessonTransaction}</TableCell>
                                                <TableCell align="right">{historyRow.transaction}</TableCell>
                                            </TableRow>
                                        )) : ""}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </Fragment>
        ) : "";
    }

    Row.propTypes = {
        row: PropTypes.shape({
            name: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            lessonDay: PropTypes.string.isRequired
        }).isRequired,
    };

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };

    //eslint-disable-next-line
    return (!loading && !historyLoading) || (data && historys) || typeof data !== "array" ? (
        <Paper sx={{ width: '100%', mb: 2 }}>
            <EnhancedTableToolbar />
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {
                            stableSort(rows, getComparator(order, orderBy))
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <Row key={row.name} row={row} />
                                ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </Paper>
    ) : "";
}

export default CollapsibleTable