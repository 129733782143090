import { useState } from "react";
import axios from "axios";
import WeeklyCalendarV2 from "./WeeklyCalendarV2";
import DisplayWeeklySchedule from "./DisplayWeeklySchedule";
import useAuth from "../../Hooks/useAuth";
import useRefreshToken from "../../Hooks/useRefreshToken";

const WeeklyLessons = () => {
  const [day, setDay] = useState(
    `${new Date().getDay()}, ${new Date().getDate()}`
  );
  const [today, setToday] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState();
  const { auth, access } = useAuth();
  const refresh = useRefreshToken();

  const getDailySchedule = async (newValue) => {
    setLoading(true);
    var refreshed;
    try {
      await access(auth.accessToken);
    } catch (err) {
      refreshed = await refresh();
    } finally {
      try {
        const response = await axios.post(
          "https://api.suzukitree.com/fetchDailySchedule",
          {
            userId: refreshed ? refreshed.userId : auth.userId,
            day: newValue.split(","),
            today: today
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${
                refreshed ? refreshed.accessToken : auth.accessToken
              }`,
            },
          }
        );
        setSchedule(response.data);
      } catch (err) {
        if (err.response.data.status === "error") {
          console.log(err.response.data.message);
          setSchedule(err.response.data.message);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <WeeklyCalendarV2
        day={day}
        setDay={setDay}
        setSchedule={setSchedule}
        setLoading={setLoading}
        getDailySchedule={getDailySchedule}
        today={today}
        setToday={setToday}
      ></WeeklyCalendarV2>
      <DisplayWeeklySchedule
        weeklySchedule={schedule}
        getDailySchedule={getDailySchedule}
        day={day}
        loading={loading}
      ></DisplayWeeklySchedule>
    </>
  );
};

export default WeeklyLessons;
