import { useEffect, useState } from "react";
import moment from "moment";
import { Stack } from "@mui/material";
import Fab from '@mui/material/Fab';
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const months = {
    0: "January",
    1: "Feburary",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
};


const MonthlyCalendar = ({ day, setDay, getDailySchedule, today, setToday }) => {
    const [days, setDays] = useState([]);
    const [weeks, setWeeks] = useState([]);
    const [fetch, setFetch] = useState(false);

    useEffect(() => {
        setDays([]);
        setWeeks([]);
        setDay(`${today.getDay()}, ${today.getDate()}`);
        const getDays = () => {
            for (let i = 0; i < new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate(); i++) {
                setDays((oldDays) => [
                    ...oldDays,
                    i + 1,
                ]);
            }
        };

        getDays();
        getDailySchedule(`${today.getDay()}, ${today.getDate()}`);
        // eslint-disable-next-line
    }, [fetch]);

    useEffect(() => {
        setWeeks([]);
        if (new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate() === days.length) {
            const position = new Date(today.getFullYear(), today.getMonth(), 1).getDay();
            const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDay();
            for (let i = 1; i <= 42; i++) {
                if (i % 7 === 0 && i !== 0) {
                    if (i === 7) {
                        setWeeks((oldWeeks) => [
                            ...oldWeeks,
                            [...new Array(position).fill(""), ...days.slice(0, 7 - position)]
                        ]);
                    } else {
                        if (days.slice(i - (7 + position), i - position).length === 7) {
                                setWeeks((oldWeeks) => [
                                    ...oldWeeks,
                                    days.slice(i - (7 + position), i - position)
                                ]);
                            
                        } else {
                            if (days.slice(i - (7 + position), i - position)[days.slice(i - (7 + position), i - position).length - 1] === new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()) {
                                setWeeks((oldWeeks) => [
                                    ...oldWeeks,
                                    [...days.slice((lastDayOfMonth + 1) * -1), ...new Array(7 - (lastDayOfMonth + 1)).fill("")]
                                ]);
                            }
                        }

                    }
                }
            }
        }
    }, [days]);

    const handleChange = (event, newValue) => {
        if (event.target.value !== "") {
            setDay(`${new Date(today.getFullYear(), today.getMonth(), event.target.value).getDay()}, ${event.target.value}`);
            getDailySchedule(`${new Date(today.getFullYear(), today.getMonth(), event.target.value).getDay()}, ${event.target.value}`);
        }
    };

    const upwards = (event) => {
        setToday(new Date(moment(today).subtract(1, 'months')));
        setFetch(!fetch);
    }

    const downwards = (event) => {
        setToday(new Date(moment(today).add(1, 'months')));
        setFetch(!fetch);
    }

    return weeks.length > 0 ? (
        <Container maxWidth="sm">
            <Stack direction="row" justifyContent="center">
                <Typography variant="h6">
                    <b>
                        {months[today.getMonth()]} {today.getFullYear()}
                    </b>
                </Typography>
            </Stack>
            <Grid xs={12} mb={2}>
                <Button variant="outlined" sx={{ height: "45px" }} onClick={upwards} fullWidth>&#x2191;</Button>
            </Grid>
            {weeks.map(function (week, i) {
                return (<><Stack direction="row" justifyContent="center" spacing={2} key={i}>
                    {
                        week.map((dayValue, i) => {
                            return <Fab onClick={handleChange} value={dayValue} color={day.split(',')[1] == dayValue ? 'primary' : 'default'}>{dayValue}</Fab>
                        })
                    }
                </Stack><br></br></>)
            })}
            <Grid xs={12}>
                <Button variant="outlined" sx={{ height: "45px" }} onClick={downwards} fullWidth>&#x2193;</Button>
            </Grid>
        </Container>
    ) : ""
}

export default MonthlyCalendar