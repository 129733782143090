import * as React from 'react';
import moment from "moment";
import axios from "axios";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import useAuth from "../../Hooks/useAuth";
import useRefreshToken from "../../Hooks/useRefreshToken";

const SortTable = ({ headRow, values, clicked }) => {
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [loadReceipts, setLoadReceipts] = React.useState(true);
    const [receipts, setReceipts] = React.useState([]);
    const { auth, access } = useAuth();
    const refresh = useRefreshToken();

    React.useEffect(() => {
        const fetchReceipts = async () => {
            var refreshed;
            try {
                try {
                    await access(auth.accessToken);
                } catch (err) {
                    refreshed = await refresh();
                } finally {
                    const res = await axios.post(
                        "https://api.suzukitree.com/fetchReceipts",
                        {
                            userId: refreshed ? refreshed.userId : auth.userId,
                            startDate: values.startDate,
                            endDate: values.endDate
                        },
                        {
                            headers: {
                                "Content-type": "application/json",
                                Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken}`,
                            },
                        }
                    );
                    setReceipts(res.data);
                    setLoadReceipts(!loadReceipts);
                }
            } catch (err) {
                console.log(err);
                if (err.response.data.message) {
                    setReceipts(err.response.data.message);
                } else {
                    setReceipts(
                        "Something went wrong while to fetch your students receipts please refresh the page to try again"
                    );
                }
            }
        }

        fetchReceipts();

        // eslint-disable-next-line
    }, [clicked]);

    React.useEffect(() => {
        setRows([]);
        if (receipts?.length > 0) {
            //eslint-disable-next-line
            receipts.map((row) => {
                const getStudent = async () => {
                    var refreshed;
                    try {
                        try {
                            await access(auth.accessToken);
                        } catch (err) {
                            refreshed = await refresh();
                        } finally {
                            const res = await axios.post(
                                "https://api.suzukitree.com/fetchStudent",
                                {
                                    userId: refreshed ? refreshed.userId : auth.userId,
                                    studentId: row.studentId
                                },
                                {
                                    headers: {
                                        "Content-type": "application/json",
                                        Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken}`,
                                    },
                                }
                            );
                            setRows(rows => ([...rows, { name: res.data[0]?.name, email: res.data[0]?.email, date: row.date, grandTotal: row.grandTotal, lessons: row.lessons, lessonDuration: row.lessonDuration, lessonPrice: row.lessonPrice, rentalInstrument: row.rentalInstrument, rentalFee: row.rentalFee, specialDescription: row.specialDesc, specialFee: row.specialFee }]))
                        }
                    } catch (err) {
                        if (err.response.data.message) {
                            console.log(err.response.data.message)
                        } else {
                            console.log("Something went wrong while to fetch that student's data");
                        }
                    }
                }
                getStudent();
            });
        }
        setLoading(!loading);

        // eslint-disable-next-line
    }, [loadReceipts]);

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const headCells = [
        ...headRow
    ];

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
    };

    const EnhancedTableToolbar = () => {
        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 }
                }}
            >
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Income
                </Typography>

                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        );
    };

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('date');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(-1);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return rows.length > 0 ? (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2, overflow: 'hidden' }}>
                <EnhancedTableToolbar />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            <TableCell padding="checkbox">
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">{rows.slice(rowsPerPage !== -1 ? page * rowsPerPage : 0, rowsPerPage !== -1 ? page * rowsPerPage + rowsPerPage : rows.length).reduce((a, b) => a + b.grandTotal, 0)}</TableCell>
                            <TableCell align="right">{rows.slice(rowsPerPage !== -1 ? page * rowsPerPage : 0, rowsPerPage !== -1 ? page * rowsPerPage + rowsPerPage : rows.length).reduce((a, b) => a + b.lessons, 0)}</TableCell>
                            <TableCell align="right">{rows.slice(rowsPerPage !== -1 ? page * rowsPerPage : 0, rowsPerPage !== -1 ? page * rowsPerPage + rowsPerPage : rows.length).reduce((a, b) => a + b.lessonPrice, 0)}</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">{rows.slice(rowsPerPage !== -1 ? page * rowsPerPage : 0, rowsPerPage !== -1 ? page * rowsPerPage + rowsPerPage : rows.length).reduce((a, b) => a + (typeof b.rentalFee === "number" ? b.rentalFee : 0), 0)}</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">{rows.slice(rowsPerPage !== -1 ? page * rowsPerPage : 0, rowsPerPage !== -1 ? page * rowsPerPage + rowsPerPage : rows.length).reduce((a, b) => a + (typeof b.rentalFee === "number" ? b.specialFee : 0), 0)}</TableCell>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(rowsPerPage !== -1 ? page * rowsPerPage : 0, rowsPerPage !== -1 ? page * rowsPerPage + rowsPerPage : rows.length)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row._id}
                                        >
                                            <TableCell padding="checkbox">
                                            </TableCell>
                                            <TableCell>{row?.name}</TableCell>
                                            <TableCell align="right">{row?.email}</TableCell>
                                            <TableCell align="right">{moment(row?.date).format('MMMM Do YYYY, h:mm a')}</TableCell>
                                            <TableCell align="right">{row?.grandTotal}</TableCell>
                                            <TableCell align="right">{row?.lessons}</TableCell>
                                            <TableCell align="right">{row?.lessonPrice}</TableCell>
                                            <TableCell align="right">{row?.rentalInstrument}</TableCell>
                                            <TableCell align="right">{row?.rentalFee}</TableCell>
                                            <TableCell align="right">{row?.specialDesc}</TableCell>
                                            <TableCell align="right">{row?.specialFee}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                            <TableRow
                                hover
                                tabIndex={-1}
                            >
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    ) : "";
}

export default SortTable