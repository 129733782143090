import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import useAuth from "../../Hooks/useAuth";
import useRefreshToken from "../../Hooks/useRefreshToken";

const initalValues = {
  date: moment().format("yyyy-MM-DD"),
  history: "",
};

const DailyScheduleCard = ({
  dailySchedule,
  id,
  active,
  setActive,
  getDailySchedule,
  day,
}) => {
  const [values, setValues] = useState(initalValues);
  const [input, setInput] = useState("");
  const [buttonText, setButtonText] = useState("Add history");
  const { auth, access } = useAuth();
  const refresh = useRefreshToken();

  const onSubmitHistory = async (event) => {
    event.preventDefault();
    if (values.date && values.history) {
      var refreshed;
      try {
        await access(auth.accessToken);
      } catch (err) {
        refreshed = await refresh();
      } finally {
        try {
          const response = await axios.post(
            "https://api.suzukitree.com/addHistory",
            {
              userId: refreshed ? refreshed.userId : auth.userId,
              studentId: dailySchedule?.classDate ? dailySchedule.studentId : dailySchedule._id,
              date: values.date,
              history: values.history,
              type: dailySchedule?.classDate ? "single" : "weekly"
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken
                  }`,
              },
            }
          );
          Swal.fire({
            title: "Added!",
            text: response.data.message,
            icon: response.data.status,
            confirmButtonText: "Ok!",
          }).then((result) => {
            if (result.isConfirmed) {
              setValues(initalValues);
              getDailySchedule(day);
            }
          });
        } catch (err) {
          if (err.response.data.status === "error") {
            Swal.fire({
              title: "Operation Failed!",
              text: err.response.data.message,
              icon: err.response.data.status,
              confirmButtonText: "Ok!",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }
        }
      }
    } else {
      Swal.fire({
        title: "Operation Failed!",
        text: "Please enter a date and a history value",
        icon: "error",
        confirmButtonText: "Ok!",
      });
    }
  };

  const onChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (active) {
      setInput(
        active[0] === id &&
          (active[1] === "Add history") ? (
          <Box
            id="addHistoryForm"
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={onSubmitHistory}
          >
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="date"
                  label="Date"
                  name="date"
                  type="date"
                  value={values.date}
                  InputLabelProps={{ shrink: true }}
                  onChange={onChange}
                  required
                ></TextField>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  id="history"
                  label="Today's history"
                  name="history"
                  value={values.history}
                  onChange={onChange}
                  required
                ></TextField>
              </Grid>
              <Grid item xs={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="success"
                  sx={{ height: "55px" }}
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : ""
      );
      setButtonText(
        active[0] === id && active[1] === "Add history"
            ? "Cancel"
            : "Add history"
      );
    }
    //eslint-disable-next-line
  }, [active, values]);

  const onClickHistory = (event) => {
    event.preventDefault();
    setActive([id, event.target.value]);
  };

  const removeLatestHistory = async (event) => {
    event.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
      icon: "info",
      text: "Remember, changes cannot be reverted!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        var refreshed;
        try {
          await access(auth.accessToken);
        } catch (err) {
          refreshed = await refresh();
        } finally {
          try {
            const response = await axios.post(
              "https://api.suzukitree.com/removeLatestHistory",
              {
                userId: refreshed ? refreshed.userId : auth.userId,
                studentId: dailySchedule?.classDate ? dailySchedule.studentId : dailySchedule._id,
                type: dailySchedule?.classDate ? "single" : "weekly"
              },
              {
                headers: {
                  "Content-type": "application/json",
                  Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken
                    }`,
                },
              }
            );
            Swal.fire({
              title: "Removed!",
              text: response.data.message,
              icon: response.data.status,
              confirmButtonText: "Ok!",
            }).then((result) => {
              if (result.isConfirmed) {
                setValues(initalValues);
                getDailySchedule(day);
              }
            });
          } catch (err) {
            if (err.response.data.status === "error") {
              Swal.fire({
                title: "Operation Failed!",
                text: err.response.data.message,
                icon: err.response.data.status,
                confirmButtonText: "Ok!",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            }
          }
        }
      }
    });
  };

  return (
    <Card sx={{ marginTop: "25px" }}>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ fontWeight: 550 }}
        >
          {dailySchedule?.startTime && dailySchedule?.endTime
            ? `${moment(`2000-01-20T${dailySchedule.startTime}`).format(
              "hh:mm a"
            )} - ${moment(`2000-01-20T${dailySchedule.endTime}`).format(
              "hh:mm a"
            )}`
            : ""}
        </Typography>
        <Typography variant="body1">
          {dailySchedule.instruments.length > 1
            ? dailySchedule.instruments.map(
              (instrument) =>
                `${instrument} ${dailySchedule.instruments[
                  dailySchedule.instruments.length - 1
                ] === instrument
                  ? ""
                  : "&"
                } `
            )
            : dailySchedule.instruments[0]}{" "}
          {dailySchedule.instruments.length > 1 ? "Classes" : "Class"}
        </Typography>
        <Typography variant="body1" sx={{ paddingTop: "10px" }}>
          {dailySchedule.name}
        </Typography>
        {dailySchedule?.history.map((obj, i) => (
          <Typography
            variant="body1"
            sx={{
              paddingTop: i === 0 ? "25px" : "",
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
            }}
            key={i}
          >
            <Grid container direction="row" alignItems="center" wrap="nowrap">
              <Grid item>
                {!obj.date ||
                  obj.date === "recently deleted" ||
                  obj.history === "recently deleted"
                  ? "No history found"
                  : obj.date}{" "}
                {!obj.history ||
                  obj.date === "recently deleted" ||
                  obj.history === "recently deleted"
                  ? ""
                  : obj.history}
              </Grid>
              <Grid item>
                {i > 0 ||
                  obj.date === "recently deleted" ||
                  obj.history === "recently deleted" ||
                  obj.date === "" ||
                  obj.history === "" ? (
                  ""
                ) : (
                  <DeleteIcon
                    onClick={removeLatestHistory}
                    sx={{ cursor: "pointer" }}
                  ></DeleteIcon>
                )}
              </Grid>
            </Grid>
          </Typography>
        ))}
        {input}
      </CardContent>
      <CardActions>
        <Button size="small" onClick={onClickHistory} value={buttonText}>
          {buttonText}
        </Button>
      </CardActions>
    </Card>
  );
};

export default DailyScheduleCard;
