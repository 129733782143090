//eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import ProtectedRoute from "./Components/ProtectedRoute";
import AnonymousRoute from "./Components/AnonymousRoute";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import ForgotPassword from "./Components/ForgotPassword";
import ResetPassword from "./Components/ResetPassword";
import Verification from "./Components/Verify";
import Lessons from "./Components/Lessons";
import Students from "./Components/Students";
import Billing from "./Components/Billing";
import Summary from "./Components/Summary";
import Settings from "./Components/Settings";

function App() {
  const signup = async (user) => {
    return await axios.post("https://api.suzukitree.com/signup", user, {
      headers: {
        "Content-type": "application/json",
      },
    });
  };

  const login = async (user) => {
    return await axios.post("https://api.suzukitree.com/login", user, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
  };

  const verifyUser = async (token) => {
    return await axios.post("https://api.suzukitree.com/verifyUser", token, {
      headers: {
        "Content-type": "application/json",
      },
    });
  };

  const resetPasswordAuth = async (token) => {
    return await axios.post("https://api.suzukitree.com/resetPasswordAuth", token, {
      headers: {
        "Content-type": "application/json",
      },
    });
  };

  const resetPassword = async (data) => {
    return await axios.post("https://api.suzukitree.com/resetPassword", data, {
      headers: {
        "Content-type": "application/json",
      },
    });
  };

  const forgotPassword = async (email) => {
    return await axios.post("https://api.suzukitree.com/forgotPassword", email, {
      headers: {
        "Content-type": "application/json",
      },
    });
  };

  return (
    <Router>
      <Routes>
        {/* <Route element={<PersistLogin></PersistLogin>}> */}
        <Route element={<ProtectedRoute></ProtectedRoute>}>
          <Route path="/" exact element={<Lessons></Lessons>}></Route>
          <Route path="/students" exact element={<Students></Students>}></Route>
          <Route path="/billing" exact element={<Billing></Billing>}></Route>
          <Route path="/summary" exact element={<Summary></Summary>}></Route>
          <Route path="/settings" exact element={<Settings></Settings>}></Route>
        </Route>
        {/* </Route> */}
        <Route element={<AnonymousRoute></AnonymousRoute>}>
          <Route
            path="/login"
            exact
            element={<Login login={login}></Login>}
          ></Route>
          <Route
            path="/signup"
            exact
            element={<Signup signup={signup}></Signup>}
          ></Route>
          <Route
            path="/forgot-password"
            exact
            element={<ForgotPassword forgotPassword={forgotPassword}></ForgotPassword>}
          ></Route>
        </Route>
        <Route
          path="/verify-user/:token"
          exact
          element={<Verification verifyUser={verifyUser}></Verification>}
        ></Route>
        <Route
          path="/reset-password/:token"
          exact
          element={<ResetPassword resetPassword={resetPassword} resetPasswordAuth={resetPasswordAuth}></ResetPassword>}
        ></Route>
      </Routes>
    </Router>
  );
}

export default App;
