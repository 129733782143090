import { useState, useContext } from "react";
import { Link as Redirect, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AuthContext from "../../Context/AuthProvider";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

const initalValues = {
  email: "",
  password: "",
  rememberMe: false,
};
const validateOnChange = true;

const LoginForm = ({ login }) => {
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);
  const [values, setValues] = useState(initalValues);
  const [errors, setErrors] = useState({ email: "", password: "" });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      try {
        const response = await login({
          email: values.email,
          password: values.password,
          rememberMe: values.rememberMe
        });

        if (response.data.status === "success") {
          setAuth({
            userId: response.data.userId,
            accessToken: response.data.accessToken,
          });
          navigate("/");
        }
      } catch (err) {
        if (err.response) {
          Swal.fire({
            title: "Operation Failed!",
            text: err.response.data.message ? err.response.data.message : err.response.data,
            icon: "error",
            confirmButtonText: "Ok!",
          });
        }
      }
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      temp.email = fieldValues.email
        ? /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
            fieldValues.email
          )
          ? ""
          : "This email is not valid."
        : "This field is required.";
    }
    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : "This field is required.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const onChange = (event) => {
    if (event.target.name === "rememberMe") {
      setValues({
        ...values,
        [event.target.name]: !values.rememberMe,
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    }

    if (validateOnChange) {
      if (event.target.name === "rememberMe") {
        validate({ [event.target.name]: !values.rememberMe });
      } else {
        validate({ [event.target.name]: event.target.value });
      }
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{ mt: 1 }}
      autoComplete="off"
    >
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        onChange={onChange}
        value={values.email}
        autoFocus
        {...(errors.email && { error: true, helperText: errors.email })}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        onChange={onChange}
        value={values.password}
        {...(errors.password && { error: true, helperText: errors.password })}
      />
      <FormControlLabel
        control={
          <Checkbox
            name="rememberMe"
            id="rememberMe"
            color="primary"
            onChange={onChange}
            value={values.rememberMe}
          />
        }
        label="Remember me"
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
        Login
      </Button>
      <Grid container>
        <Grid item xs>
          <Redirect to="/forgot-password">
            <Link variant="body2">Forgot password?</Link>
          </Redirect>
        </Grid>
        <Grid item>
          <Redirect to="/signup">
            <Link variant="body2">{"Don't have an account? Sign Up"}</Link>
          </Redirect>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginForm;
