import { useState, useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../Hooks/useAuth";
import useRefreshToken from "../Hooks/useRefreshToken";

const ProtectedRoute = () => {
  const [authorized, setAuthorized] = useState();
  const { auth, access } = useAuth();
  const location = useLocation();
  const refresh = useRefreshToken();

  useEffect(() => {
    const authorize = async () => {
      try {
        await access(auth.accessToken);
        setAuthorized(true);
      } catch (err) {
        try {
          const response = await refresh();
          response.accessToken ? setAuthorized(true) : setAuthorized(false);
        } catch (err) {
          setAuthorized(false);
        }
      }
    };

    authorize();
    //eslint-disable-next-line
  }, []);

  if (authorized === undefined) {
    return null;
  }

  return authorized ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default ProtectedRoute;
