import { useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import useAuth from "../../Hooks/useAuth";
import useRefreshToken from "../../Hooks/useRefreshToken";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const abbreviations = {
  Monday: "Mon",
  Tuesday: "Tue",
  Wednesday: "Wed",
  Thursday: "Thu",
  Friday: "Fri",
  Saturday: "Sat",
  Sunday: "Sun"
};

const initalValues = {
  datetime: moment().format("yyyy-MM-DDThh:mm"),
  duration: 5 
};

const CardComponent = ({ student, setRefresh, setKeys, setValue, setEditId, setRerender, rerender, setAddState }) => {
  const { auth, access } = useAuth();
  const refresh = useRefreshToken();
  const [values, setValues] = useState(initalValues);
  const [open, setOpen] = useState(false);

  const onChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    var refreshed;
    if (values.duration < 5 || values.duration > 300) {
      Swal.fire({
        title: "Operation Failed!",
        text: "Duration must be greater than 5 and less than 300",
        icon: "error",
        confirmButtonText: "Ok!",
      });
    } else {
      try {
        await access(auth.accessToken);
      } catch (err) {
        refreshed = await refresh();
      } finally {
        try {
          const response = await axios.post(
            "https://api.suzukitree.com/addClass",
            {
              userId: refreshed ? refreshed.userId : auth.userId,
              studentId: student._id,
              classDate: values.datetime,
              duration: Number(values.duration)
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken
                  }`,
              },
            }
          );
          Swal.fire({
            title: "Added!",
            text: response.data.message,
            icon: response.data.status,
            confirmButtonText: "Ok!",
          });
        } catch (err) {
          if (err.response.data.status === "error") {
            Swal.fire({
              title: "Operation Failed!",
              text: err.response.data.message,
              icon: err.response.data.status,
              confirmButtonText: "Ok!",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }
        }
      }
      setValues(initalValues);
    }
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setValues(initalValues);
  };

  return (
    <>
      <Card sx={{ maxWidth: 400 }}>
        <CardContent>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Typography gutterBottom variant="h6" component="div">
              {student.name}
            </Typography>
            <Typography gutterBottom variant="h7" component="div"></Typography>
          </Stack>
          <Typography variant="body2" color="text.secondary">
            {student.birthday}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {student.email}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {student.phoneNumber}
          </Typography>
          <Stack direction="row">
            <Typography variant="body2">
              {abbreviations[student.lessonDays]}
            </Typography>
            <Typography variant="body2">
              {student.startTime && student.endTime
                ? `${moment(`2000-01-20T${student.startTime}`).format(
                  "hh:mm a"
                )} - ${moment(`2000-01-20T${student.endTime}`).format(
                  "hh:mm a"
                )}`
                : ""}
            </Typography>
          </Stack>
          {student.instruments.map((instrument, i) => (
            <Typography variant="body2" color="text.secondary" key={i}>
              {instrument}
            </Typography>
          ))}
        </CardContent>
        <CardActions>
          <Button size="small" id={student._id} onClick={async (event) => {
            setKeys({ lessons: ["Weekly", "Monthly"], students: ["Sort by", "Edit"], billing: ["Bill", "History"], summary: ["Income", "Lessons"] });
            setValue("Edit");
            setEditId(event.target.id);
            setAddState(false);
            setRerender(!rerender);
          }}>
            Edit
          </Button>
          <Button
            size="small"
            id={student._id}
            onClick={async (event) => {
              Swal.fire({
                title: "Are you sure?",
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: `No`,
                icon: "info",
                text: "Remember, changes cannot be reverted!"
              }).then(async (result) => {
                if (result.isConfirmed) {
                  try {
                    var refreshed;
                    try {
                      await access(auth.accessToken);
                    } catch (err) {
                      refreshed = await refresh();
                    } finally {
                      const response = await axios.post(
                        "https://api.suzukitree.com/deleteStudent",
                        {
                          userId: refreshed ? refreshed.userId : auth.userId,
                          studentId: event.target.id,
                        },
                        {
                          headers: {
                            "Content-type": "application/json",
                            Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken
                              }`,
                          },
                        }
                      );
                      if (response.data.status === "success") {
                        Swal.fire({
                          title: "Deleted!",
                          text: response.data.message,
                          icon: response.data.status,
                          confirmButtonText: "Ok!",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setRefresh(true);
                          }
                        });
                      }
                    }
                  } catch (err) {
                    Swal.fire({
                      title: "Operation Failed!",
                      text: err.response.data.message,
                      icon: err.response.data.status,
                      confirmButtonText: "Ok!",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        window.location.reload();
                      }
                    });
                  }
                }
              });
            }}
          >
            Delete
          </Button>
          <Button size="small" id={student._id} onClick={handleClickOpen}>
            Add class
          </Button>
        </CardActions>
      </Card>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add class</DialogTitle>
        <DialogContent>
          <DialogContentText color="primary">
            This'll add a one-time class.
          </DialogContentText>
          <TextField
            fullWidth
            autoFocus
            margin="normal"
            variant="standard"
            label="Date"
            name="datetime"
            type="datetime-local"
            value={values.datetime}
            InputLabelProps={{ shrink: true }}
            onChange={onChange}
            required
          ></TextField>
          <TextField
            fullWidth
            autoFocus
            margin="normal"
            variant="standard"
            label="Duration (mins)"
            name="duration"
            type="number"
            value={values.duration}
            InputProps={{ inputProps: { min: 5, max: 300, step: 5 } }}
            InputLabelProps={{ shrink: true }}
            onChange={onChange}
            required
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onSubmit}>Add class</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CardComponent;
