import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const SelectField = ({
  values,
  label,
  name,
  inputValues,
  setInputValues,
  selectValue,
  setSelectValue,
}) => {
  const handleChange = (event) => {
    if (setSelectValue) {
      setSelectValue(event.target.value);
    }

    if (typeof setInputValues === "function") {
      setInputValues({
        ...inputValues,
        [event.target.name]: event.target.value,
      });
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={selectValue ? selectValue : Object.values(inputValues)[0]}
        onChange={handleChange}
        displayEmpty
        label={label}
        name={name}
      >
        {values.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
