import { useState } from "react";
import DailyScheduleCard from "./DailyScheduleCard";

const DisplayWeeklySchedule = ({ weeklySchedule, getDailySchedule, day, loading }) => {
  const [active, setActive] = useState(null);

  return typeof weeklySchedule === "string" || !weeklySchedule
    ? weeklySchedule
    : weeklySchedule.map((dailySchedule, i) => (
      <DailyScheduleCard
        dailySchedule={dailySchedule}
        id={i}
        key={i}
        active={active}
        setActive={setActive}
        getDailySchedule={getDailySchedule}
        day={day}
      ></DailyScheduleCard>
    ));
};

export default DisplayWeeklySchedule;
