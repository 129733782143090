import { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../Hooks/useAuth";
import useRefreshToken from "../Hooks/useRefreshToken";

const AnonymousRoute = () => {
  const [authorized, setAuthorized] = useState();
  const { auth, access } = useAuth();
  const refresh = useRefreshToken();

  useEffect(() => {
    const authorize = async () => {
      try {
        await access(auth.accessToken);
        setAuthorized(false);
      } catch (err) {
        try {
          const response = await refresh();
          response.accessToken ? setAuthorized(false) : setAuthorized(true);
        } catch (err) {
          setAuthorized(true);
        }
      }
    };

    authorize();
    //eslint-disable-next-line
  }, []);

  if (authorized === undefined) {
    return null;
  }

  return authorized ? <Outlet /> : <Navigate to="/" />;
};

export default AnonymousRoute;
