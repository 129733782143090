import { useState, useEffect } from "react";
import { utils, writeFile } from "xlsx";
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SelectField from "./Subcomponents/SelectFieldV2";
import Header from "./Subcomponents/Header";
import Footer from "./Subcomponents/Footer";
import { Container, Grid, Accordion, AccordionDetails, AccordionSummary, Typography, CssBaseline, Button, TextField } from "@mui/material";
import Swal from "sweetalert2";
import useAuth from "../Hooks/useAuth";
import useRefreshToken from "../Hooks/useRefreshToken";

const theme = createTheme();

const Settings = () => {
  const [expanded, setExpanded] = useState(false);
  const [students, setStudents] = useState(null);
  const [history, setHistory] = useState(null);
  const [income, setIncome] = useState(null);
  const [lessons, setLessons] = useState(null);
  const [download, setDownload] = useState(false);
  const [businessRef, setBusinessRef] = useState({
    subject: "Business Name",
    body: ""
  });
  const { auth, access } = useAuth();
  const refresh = useRefreshToken();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onChange = (event) => {
    setBusinessRef({
      ...businessRef,
      [event.target.name]: event.target.value,
    });
  };

  const handleOnExport = () => {
    setDownload(!download);
    var wb = utils.book_new(),
      ws1 = utils.json_to_sheet(students),
      ws2 = utils.json_to_sheet(history),
      ws3 = utils.json_to_sheet(income),
      ws4 = utils.json_to_sheet(lessons);

    utils.book_append_sheet(wb, ws1, "students");
    utils.book_append_sheet(wb, ws2, "history");
    utils.book_append_sheet(wb, ws3, "income");
    utils.book_append_sheet(wb, ws4, "lessons");
    writeFile(wb, "data.xlsx");
  }

  useEffect(() => {
    const fetchStudents = async () => {
      var refreshed;
      try {
        try {
          await access(auth.accessToken);
        } catch (err) {
          refreshed = await refresh();
        } finally {
          const response = await axios.post(
            "https://api.suzukitree.com/fetchStudents",
            { userId: refreshed ? refreshed.userId : auth.userId },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken
                  }`,
              },
            }
          );
          setStudents(response.data);
        }
      } catch (err) {
        if (err.response.data.message) {
          setStudents(err.response.data.message);
        } else {
          setStudents(
            "Something went wrong while to fetch your students please refresh the page to try again"
          );
        }
      }
    };
    const fetchHistory = async () => {
      var refreshed;
      try {
        try {
          await access(auth.accessToken);
        } catch (err) {
          refreshed = await refresh();
        } finally {
          const response = await axios.post(
            "https://api.suzukitree.com/fetchHistory",
            { userId: refreshed ? refreshed.userId : auth.userId },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken
                  }`,
              },
            }
          );
          setHistory(response.data);
        }
      } catch (err) {
        if (err.response.data.message) {
          setHistory(err.response.data.message);
        } else {
          setHistory(
            "Something went wrong while to fetch your students' history please refresh the page to try again"
          );
        }
      }
    };
    const fetchReceipts = async () => {
      var refreshed;
      try {
        try {
          await access(auth.accessToken);
        } catch (err) {
          refreshed = await refresh();
        } finally {
          const response = await axios.post(
            "https://api.suzukitree.com/fetchReceipts",
            { userId: refreshed ? refreshed.userId : auth.userId, startDate: "01/01/2000", endDate: new Date() },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken
                  }`,
              },
            }
          );
          setIncome(response.data);
        }
      } catch (err) {
        if (err.response.data.message) {
          setIncome(err.response.data.message);
        } else {
          setIncome(
            "Something went wrong while to fetch your income please refresh the page to try again"
          );
        }
      }
    };
    const fetchLessons = async () => {
      var refreshed;
      try {
        try {
          await access(auth.accessToken);
        } catch (err) {
          refreshed = await refresh();
        } finally {
          const response = await axios.post(
            "https://api.suzukitree.com/fetchLessons",
            { userId: refreshed ? refreshed.userId : auth.userId, startDate: "01/01/2000", endDate: new Date() },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken
                  }`,
              },
            }
          );
          setLessons(response.data);
        }
      } catch (err) {
        if (err.response.data.message) {
          setLessons(err.response.data.message);
        } else {
          setLessons(
            "Something went wrong while to fetch your lessons please refresh the page to try again"
          );
        }
      }
    };

    fetchStudents();
    fetchHistory();
    fetchReceipts();
    fetchLessons();

    //eslint-disable-next-line
  }, [download]);

  const onInsert = async (event) => {
    event.preventDefault();
    var refreshed;
    try {
      await access(auth.accessToken);
    } catch (err) {
      refreshed = await refresh();
    } finally {
      try {
        const response = await axios.post(
          "https://api.suzukitree.com/changeBusinessRef",
          {
            userId: refreshed ? refreshed.userId : auth.userId,
            subject: businessRef.subject,
            body: businessRef.body
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken
                }`,
            },
          }
        );
        Swal.fire({
          title: "Added!",
          text: response.data.message,
          icon: response.data.status,
          confirmButtonText: "Ok!",
        }).then((result) => {
          if (result.isConfirmed) {
            setBusinessRef({
              subject: "Business Name",
              body: "",
            });
          }
        });
      } catch (err) {
        if (err.response.data.status === "error") {
          Swal.fire({
            title: "Operation Failed!",
            text: err.response.data.message,
            icon: err.response.data.status,
            confirmButtonText: "Ok!",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
      }
    }

  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header title={"Settings"}></Header>
      <br></br>
      <br></br>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Download data
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ color: 'text.secondary' }}>
                <Typography>
                  Select below to pick what data you want, and click the button to download your data.
                </Typography>
                <br></br>
                <Button variant="contained" onClick={handleOnExport}>Click me to download</Button>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>Business Reference</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ color: 'text.secondary' }}>
                <Typography>
                  Change the business reference that shows on your invoices and reciepts here.
                </Typography>
                <br></br>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <SelectField
                      values={[
                        "Business Name",
                        "Business Address",
                        "Business Email",
                        "Business Phone Number"
                      ]}
                      label="Subject"
                      name="subject"
                      inputValues={businessRef}
                      setInputValues={setBusinessRef}
                    ></SelectField>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <TextField
                      fullWidth
                      id="body"
                      label="Body"
                      name="body"
                      value={businessRef.body}
                      onChange={onChange}
                      required
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="success"
                      sx={{ height: "55px" }}
                      type="submit"
                      onClick={onInsert}
                    >
                      Insert
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Instruments
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ color: 'text.primary' }}>
                  Change the group of instruments a student can learn here.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>Personal data</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
                  amet egestas eros, vitae egestas augue. Duis vel est augue.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>
      <Footer></Footer>
    </ThemeProvider>
  );
};

export default Settings;