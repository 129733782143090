import { useState } from "react";
import Swal from "sweetalert2";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const initalValues = {
  email: ""
};
const validateOnChange = true;

const ForgotPassword = ({ forgotPassword }) => {
  const [values, setValues] = useState(initalValues);
  const [errors, setErrors] = useState(initalValues);
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const onChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });

    if (validateOnChange) {
      validate({ [event.target.name]: event.target.value });
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      temp.email = fieldValues.email
        ? /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
          fieldValues.email
        )
          ? ""
          : "This email is not valid."
        : "This field is required.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      try {
        setEmailSent(true);
        const response = await forgotPassword({
          email: values.email
        });

        Swal.fire({
          title: "Sent!",
          text: response.data.message,
          icon: "success",
          confirmButtonText: "Ok!",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/login");
            setEmailSent(false);
          }
        });
      } catch (err) {
        Swal.fire({
          title: "Operation Failed!",
          text: err.response.data.message
            ? err.response.data.message
            : err.response.data,
          icon: "error",
          confirmButtonText: "Ok!",
        });
        console.log(err);
      }
    }
  };

  return (
    <div style={{ pointerEvents: emailSent ? "none" : "auto" }}>
      {!emailSent ?
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Forgot Password
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <Typography
                  component="h2"
                  variant="h6"
                  sx={{ mt: 2, textAlign: "center" }}
                >
                  Don't worry, just enter your email address associated with your
                  account and you'll be able to get your account back in no time
                </Typography>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onChange={onChange}
                  {...(errors.email && { error: true, helperText: errors.email })}
                  sx={{ mt: 2 }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Send email
                </Button>
              </Box>
            </Box>
            <Copyright sx={{ mt: 2, mb: 4 }} />
          </Container>
        </ThemeProvider> : <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress size={100} />
        </Box>}
    </div>
  );
};

export default ForgotPassword;
