import axios from "axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refreshToken = async () => {
    const response = await axios.get("https://api.suzukitree.com/refreshToken", {
      withCredentials: true,
    });
    setAuth({
      userId: response.data.userId,
      accessToken: response.data.accessToken,
    });
    return {
      userId: response.data.userId,
      accessToken: response.data.accessToken,
    };
  };
  return refreshToken;
};

export default useRefreshToken;
