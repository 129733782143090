import { createContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext();

const localState = JSON.parse(localStorage.getItem("auth"));

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(localState || {});

  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify(auth));
  }, [auth]);

  const tokenAccess = async (token) => {
    return await axios.post(
      "https://api.suzukitree.com/access",
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };

  const access = () => tokenAccess(auth.accessToken);

  return (
    <AuthContext.Provider value={{ auth, setAuth, access }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
