import Tabs from "./Tabs";

const Body = ({ title }) => {
  return (
    <main>
      <Tabs title={title}></Tabs>
    </main>
  );
};

export default Body;
