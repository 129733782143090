import { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import DisplayStudents from "./DisplayStudents";
import AddStudent from "./AddStudent";
import WeeklyLessons from "./WeeklyLessons";
import MonthlyLessons from "./MonthlyLessons";
import Bill from "./Bill";
import TransactionHistory from "./TransactionHistory";
import IncomeSummary from "./IncomeSummary";
import LessonSummary from "./LessonSummary";

const TabsComponent = ({ title }) => {
  const fetchStudents = async (
    access,
    credientials,
    refresh,
    setStudents,
    setLoading,
    setRefresh,
    advancedSearch,
    searchValue
  ) => {
    var refreshed;
    try {
      try {
        await access(credientials.accessToken);
      } catch (err) {
        refreshed = await refresh();
      } finally {
        const response = await axios.post(
          "https://api.suzukitree.com/fetchStudents",
          { userId: refreshed ? refreshed.userId : credientials.userId, advancedSearch: advancedSearch, searchValue: searchValue },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${refreshed ? refreshed.accessToken : credientials.accessToken
                }`,
            },
          }
        );
        setStudents(response.data);
        setLoading(false);
        setRefresh(false);
      }
    } catch (err) {
      if (err.response.data.message) {
        setStudents(err.response.data.message);
      } else {
        setStudents(
          "Something went wrong while to fetch your students please refresh the page to try again"
        );
      }
    }
  };

  const [keys, setKeys] = useState({ lessons: ["Weekly", "Monthly"], students: ["Sort by", "Add"], billing: ["Bill", "History"], summary: ["Income", "Lessons"] });
  const [value, setValue] = useState(keys[title.toLowerCase()][0]);
  const [display, setDisplay] = useState({});
  const [editId, setEditId] = useState("");
  const [rerender, setRerender] = useState(false);
  const [addState, setAddState] = useState(false);

  useEffect(() => {
    setDisplay({
      lessons: [<WeeklyLessons></WeeklyLessons>, <MonthlyLessons></MonthlyLessons>],
      students: [
        <DisplayStudents fetchStudents={fetchStudents} setKeys={setKeys} setDisplay={setDisplay} setValue={setValue} setEditId={setEditId} setRerender={setRerender} rerender={rerender} setAddState={setAddState}></DisplayStudents>,
        <AddStudent editId={editId} addState={addState}></AddStudent>,
      ],
      billing: [
        <Bill fetchStudents={fetchStudents}></Bill>,
        <TransactionHistory></TransactionHistory>,
      ],
      summary: [<IncomeSummary></IncomeSummary>, <LessonSummary></LessonSummary>],
    });

    //eslint-disable-next-line
  }, [rerender])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const query = {
    '@media (max-width: 600px)': {
      paddingRight: '5px',
      paddingLeft: '5px'
    }
  }

  return Object.keys(keys).length === 4 && Object.keys(display).length === 4 ? (
    <TabContext value={value}>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 1,
        }}
      >
        <Container maxWidth="sm">
          <Stack direction="row" spacing={2} justifyContent="center">
            <Tabs value={value} onChange={handleChange}>
              <Tab
                label={keys[title.toLowerCase()][0]}
                value={keys[title.toLowerCase()][0]}
                onClick={(e) => {
                  if (e.target.nextSibling.textContent === "Edit") {
                    setKeys({ lessons: ["Weekly", "Monthly"], students: ["Sort by", "Add"], billing: ["Bill", "History"], summary: ["Income", "Lessons"] });
                  }

                  setEditId("");
                  setAddState(true);
                  setRerender(!rerender);
                  setEditId(null);
                  setAddState(null);
                }}
              />
              <Tab
                label={keys[title.toLowerCase()][1]}
                value={keys[title.toLowerCase()][1]}
                onClick={(e) => {
                  console.log(e.target.textContent)
                  if (e.target.textContent === "Edit") {
                    setKeys({ lessons: ["Weekly", "Monthly"], students: ["Sort by", "Add"], billing: ["Bill", "History"], summary: ["Income", "Lessons"] });
                    setValue("Add");
                  }

                  setEditId("");
                  setAddState(true);
                  setRerender(!rerender);
                  
                }}
              />
            </Tabs>
          </Stack>
        </Container>
      </Box>
      <TabPanel value={keys[title.toLowerCase()][0]} sx={query}>
        {display[title.toLowerCase()][0]}
      </TabPanel>
      <TabPanel value={keys[title.toLowerCase()][1]} sx={query}>
        {display[title.toLowerCase()][1]}
      </TabPanel>
    </TabContext>
  ) : "";
};

export default TabsComponent;
