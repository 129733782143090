import { useState } from "react";
import axios from "axios";
import MonthlyCalendar from "./MonthlyCalendar";
import DisplayWeeklySchedule from "./DisplayWeeklySchedule";
import useAuth from "../../Hooks/useAuth";
import useRefreshToken from "../../Hooks/useRefreshToken";

const MonthlyLessons = () => {
  const [day, setDay] = useState(
    `${new Date().getDay()}, ${new Date().getDate()}`
  );
  const [today, setToday] = useState(new Date());
  const [schedule, setSchedule] = useState();
  const { auth, access } = useAuth();
  const refresh = useRefreshToken();

  const getDailySchedule = async (newValue) => {
    var refreshed;
    try {
      await access(auth.accessToken);
    } catch (err) {
      refreshed = await refresh();
    } finally {
      try {
        const response = await axios.post(
          "https://api.suzukitree.com/fetchDailySchedule",
          {
            userId: refreshed ? refreshed.userId : auth.userId,
            day: newValue.split(","),
            today: today
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken
                }`,
            },
          }
        );
        setSchedule(response.data);
      } catch (err) {
        if (err.response.data.status === "error") {
          console.log(err.response.data.message);
          setSchedule(err.response.data.message);
        }
      }
    }
  };

  return (
    <>
      <MonthlyCalendar day={day} setDay={setDay} getDailySchedule={getDailySchedule} today={today} setToday={setToday}></MonthlyCalendar>
      <DisplayWeeklySchedule
        weeklySchedule={schedule}
        getDailySchedule={getDailySchedule}
        day={day}
      ></DisplayWeeklySchedule>
    </>
  )
}

export default MonthlyLessons