import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const initalValues = {
  password: "",
  cpassword: ""
};
const validateOnChange = true;

const ResetPassword = ({ resetPassword, resetPasswordAuth }) => {
  const [values, setValues] = useState(initalValues);
  const [errors, setErrors] = useState(initalValues);
  const [auth, setAuth] = useState(null);
  const navigate = useNavigate();
  const params = useParams();

  const onChange = (event) => {
    console.log(event.target.name)
    console.log(values)
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });

    if (validateOnChange) {
      if (event.target.name === "password") {
        validate({ password: event.target.value, cpassword: values.cpassword });
      } else if (event.target.name === "cpassword") {
        validate({ password: values.password, cpassword: event.target.value });
      }

    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("password" in fieldValues) {
      temp.password = fieldValues.password
        ? fieldValues.password.length >= 8 ? fieldValues.password === fieldValues.cpassword ? "" : "Passwords are not equal" : "Password must be than 8 characters or greater."
        : "This field is required.";
    }
    if ("cpassword" in fieldValues) {
      temp.cpassword = fieldValues.cpassword
        ? fieldValues.cpassword.length >= 8 ? fieldValues.password === fieldValues.cpassword ? "" : "Passwords are not equal" : "Password must be than 8 characters or greater."
        : "This field is required.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  useEffect(() => {
    const validateUrl = async () => {
      try {
        const auth = await resetPasswordAuth({ token: params.token });
        setAuth(auth.status === 203 ? true : false);
      } catch (err) {
        setAuth(false);
      }
    };

    validateUrl();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(values);
    console.log(errors);
  }, [values, errors]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      try {
        const response = await resetPassword({
          password: values.password,
          token: params.token
        });

        Swal.fire({
          title: "Success!",
          text: response.data.message,
          icon: "success",
          confirmButtonText: "Ok!",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/login");
          }
        });
      } catch (err) {
        Swal.fire({
          title: "Operation Failed!",
          text: err.response.data.message
            ? err.response.data.message
            : err.response.data,
          icon: "error",
          confirmButtonText: "Ok!",
        });
        console.log(err);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          {auth !== null ?
            auth === false && typeof auth === "boolean" ?
              <Typography component="h2" variant="h6" sx={{ textAlign: "center" }}>
                This reset password link is invalid or expired
              </Typography>
              :
              <Box
                component="form"
                noValidate
                sx={{ mt: 1 }}
                onSubmit={handleSubmit}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="New password"
                  onChange={onChange}
                  id="password"
                  {...(errors.password && { error: true, helperText: errors.password })}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="cpassword"
                  label="Confirm new password"
                  onChange={onChange}
                  id="cpassword"
                  {...(errors.cpassword && { error: true, helperText: errors.cpassword })}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Reset password
                </Button>
              </Box> : ""}
        </Box>
        <Copyright sx={{ mt: 2, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export default ResetPassword;
