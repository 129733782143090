import { useState, useEffect } from "react";
import AdvancedSearch from "./AdvancedSearch";
import BillingCard from "./BillingCard";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import useAuth from "../../Hooks/useAuth";
import useRefreshToken from "../../Hooks/useRefreshToken";

const Bill = ({ fetchStudents, fetchTuition }) => {
  const [active, setActive] = useState(null);
  const [selectValue, setSelectValue] = useState("Name");
  const [selectInputValue, setSelectInputValue] = useState("");
  const [students, setStudents] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshCards, setRefresh] = useState(false);
  const [search, setSearch] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { auth, access } = useAuth();
  const refresh = useRefreshToken();

  useEffect(() => {
    setLoading(true);
    fetchStudents(access, auth, refresh, setStudents, setLoading, setRefresh, selectValue, selectInputValue);
    //eslint-disable-next-line
  }, [refreshCards, search]);

  const container = {
    '@media (max-width: 780px)' : {
      paddingLeft: '0px',
      paddingRight: '0px'
    }
  }

  return !loading || students ? (
    <Container sx={container}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AdvancedSearch
            selectValue={selectValue}
            setSelectValue={setSelectValue}
            selectInputValue={selectInputValue}
            setSelectInputValue={setSelectInputValue}
            setSearch={setSearch}
            search={search}
            values={["Name"]}
          ></AdvancedSearch>
        </Grid>
        {typeof students === "object" ? students.map((student, i) => (
          <Grid item key={i} xs={12}>
            <BillingCard
              student={student}
              refreshCards={refreshCards}
              setRefresh={setRefresh}
              id={i}
              active={active}
              setActive={setActive}
              fetchTuition={fetchTuition}
              emailSent={emailSent}
              setEmailSent={setEmailSent}
            ></BillingCard>
          </Grid>
        )) : "You currently have no students"}
      </Grid>
    </Container>
  ) : (
    ""
  );
};

export default Bill;
