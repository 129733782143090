import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "./StudentCard";
import AdvancedSearch from "./AdvancedSearch";
import useAuth from "../../Hooks/useAuth";
import useRefreshToken from "../../Hooks/useRefreshToken";

const DisplayStudents = ({ fetchStudents, setKeys, setValue, setEditId, setRerender, rerender, setAddState }) => {
  const [students, setStudents] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshCards, setRefresh] = useState(false);
  const [search, setSearch] = useState(false);
  const [selectValue, setSelectValue] = useState("Name");
  const [selectInputValue, setSelectInputValue] = useState("");
  const { auth, access } = useAuth();
  const refresh = useRefreshToken();

  useEffect(() => {
    setLoading(true);
    fetchStudents(access, auth, refresh, setStudents, setLoading, setRefresh, selectValue, selectInputValue);
    //eslint-disable-next-line
  }, [refreshCards, search]);

  const container = {
    '@media (max-width: 780px)' : {
      paddingLeft: '0px',
      paddingRight: '0px'
    }
  }

  return !loading || students ? (
    <Container sx={container}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AdvancedSearch
            selectValue={selectValue}
            setSelectValue={setSelectValue}
            selectInputValue={selectInputValue}
            setSelectInputValue={setSelectInputValue}
            setSearch={setSearch}
            search={search}
            values={["Name"]}
            // values={["Name", "Instrument", "Lesson Days", "Email"]}
          ></AdvancedSearch>
        </Grid>
        {typeof students === "string" || !students
          ? students
          : students.map((student, i) => (
              <Grid item key={i} xs={12} sm={6} md={4}>
                <Card
                  student={student}
                  setRefresh={setRefresh}
                  type="students"
                  setKeys={setKeys}
                  setValue={setValue}
                  setEditId={setEditId}
                  setRerender={setRerender}
                  rerender={rerender}
                  setAddState={setAddState}
                ></Card>
              </Grid>
            ))}
      </Grid>
    </Container>
  ) : (
    ""
  );
};

export default DisplayStudents;
