import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import SelectField from "./SelectField";
import useAuth from "../../Hooks/useAuth";
import useRefreshToken from "../../Hooks/useRefreshToken";

const BillingCard = ({
  student,
  refreshCards,
  setRefresh,
  id,
  active,
  setActive,
  setEmailSent,
  emailSent
}) => {
  const [tuition, setTuition] = useState(null);
  const [values, setValues] = useState({
    subject: "Lessons",
    body: "",
    type: "string",
  });
  const [buttonText, setButtonText] = useState(["Invoice", "Receipt"]);
  const [input, setInput] = useState("");
  const [localLoad, setLocalLoad] = useState(true);
  const [settings, setSettings] = useState({});
  const { auth, access } = useAuth();
  const refresh = useRefreshToken();

  const onInsert = async (event) => {
    event.preventDefault();
    if (active[1] === "Invoice" || active[1] === "Receipt") {
      if (values.subject && values.body) {
        if (
          (values.subject === "Lessons" ||
            values.subject === "Lesson price" ||
            values.subject === "Rental fee" ||
            values.subject === "Special fee") &&
          isNaN(Number(values.body))
        ) {
          Swal.fire({
            title: "Operation Failed!",
            text: "Please enter a valid body number",
            icon: "error",
            confirmButtonText: "Ok!",
          });
          values.type = "number";
        } else {
          values.type = "string";
          var refreshed;
          try {
            await access(auth.accessToken);
          } catch (err) {
            refreshed = await refresh();
          } finally {
            try {
              const response = await axios.post(
                "https://api.suzukitree.com/addTuition",
                {
                  userId: refreshed ? refreshed.userId : auth.userId,
                  studentId: student._id,
                  subject: values.subject,
                  body: values.body,
                  type: values.type,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken
                      }`,
                  },
                }
              );
              Swal.fire({
                title: "Added!",
                text: response.data.message,
                icon: response.data.status,
                confirmButtonText: "Ok!",
              }).then((result) => {
                if (result.isConfirmed) {
                  setValues({
                    subject: "Lessons",
                    body: "",
                  });
                  setRefresh(true);
                }
              });
            } catch (err) {
              if (err.response.data.status === "error") {
                Swal.fire({
                  title: "Operation Failed!",
                  text: err.response.data.message,
                  icon: err.response.data.status,
                  confirmButtonText: "Ok!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                });
              }
            }
          }
        }
      } else {
        Swal.fire({
          title: "Operation Failed!",
          text: "Please enter a subject and a body value",
          icon: "error",
          confirmButtonText: "Ok!",
        });
      }
    } else {
      Swal.fire({
        title: "Operation Failed!",
        text: "Something went wrong, please try again later (ERROR CODE 004)",
        icon: "error",
        confirmButtonText: "Ok!",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }
  };

  const onChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    setLocalLoad(true);
    const fetchAll = async () => {
      var refreshed;
      try {
        try {
          await access(auth.accessToken);
        } catch (err) {
          refreshed = await refresh();
        } finally {
          const res = await axios.post(
            "https://api.suzukitree.com/fetchTuition",
            {
              userId: refreshed ? refreshed.userId : auth.userId,
              studentId: student._id
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken
                  }`,
              },
            }
          );
          const response = await axios.post(
            "https://api.suzukitree.com/fetchBusinessRef",
            { userId: refreshed ? refreshed.userId : auth.userId },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken
                  }`,
              },
            }
          );
          setSettings(response.data);
          setTuition(res.data);
          setLocalLoad(false);
          setRefresh(false);
        }
      } catch (err) {
        if (err.response.data.message) {
          setTuition(err.response.data.message);
          setSettings(err.response.data.message);
        } else {
          setTuition(
            "Something went wrong while to fetch all the data for the billing page, please refresh the page to try again"
          );
          setSettings(
            "Something went wrong while to fetch all the data for the billing page, please refresh the page to try again"
          );
        }
      }
    };
    fetchAll();

    //eslint-disable-next-line
  }, [refreshCards]);

  useEffect(() => {
    if (active) {
      setInput(
        active[0] === id &&
          (active[1] === "Invoice" || active[1] === "Receipt") ? (
          <Box id="tuitionForm" component="form" noValidate autoComplete="off">
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <SelectField
                  values={[
                    "Lessons",
                    "Lesson price",
                    "Rental instrument",
                    "Rental fee",
                    "Other expenses",
                    "Other fees",
                  ]}
                  label="Subject"
                  name="subject"
                  inputValues={values}
                  setInputValues={setValues}
                ></SelectField>
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextField
                  fullWidth
                  id="body"
                  label="Body"
                  name="body"
                  value={values.body}
                  onChange={onChange}
                  required
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="success"
                  sx={{ height: "55px" }}
                  onClick={onInsert}
                  type="submit"
                >
                  Insert
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : (
          ""
        )
      );
      setButtonText(
        active[0] === id
          ? active[1] === "Invoice"
            ? ["Send Invoice", "Receipt"]
            : active[1] === "Receipt"
              ? ["Invoice", "Send Receipt"]
              : ["Invoice", "Receipt"]
          : ["Invoice", "Receipt"]
      );
    }
    //eslint-disable-next-line
  }, [active, values]);

  const onClickButton = (event) => {
    event.preventDefault();
    setActive([id, event.target.value]);

    if (event.target.value === "Send Invoice") {
      const sendInvoice = async () => {
        var refreshed;
        try {
          await access(auth.accessToken);
        } catch (err) {
          refreshed = await refresh();
        } finally {
          try {
            setEmailSent(true);
            const response = await axios.post(
              "https://api.suzukitree.com/sendInvoice",
              {
                userId: refreshed ? refreshed.userId : auth.userId,
                studentId: student._id
              },
              {
                headers: {
                  "Content-type": "application/json",
                  Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken
                    }`,
                },
              }
            );
            Swal.fire({
              title: "Sent!",
              text: response.data.message,
              icon: response.data.status,
              confirmButtonText: "Ok!",
            }).then((result) => {
              if (result.isConfirmed) {
                setValues({
                  subject: "Lessons",
                  body: "",
                });
                setRefresh(true);
                setEmailSent(false);
              }
            });
          } catch (err) {
            if (err.response.data.status === "error") {
              Swal.fire({
                title: "Operation Failed!",
                text: err.response.data.message,
                icon: err.response.data.status,
                confirmButtonText: "Ok!",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            }
          }
        }
      }

      sendInvoice();
    }

    if (event.target.value === "Send Receipt") {
      Swal.fire({
        title: "What would you like to do with this receipt?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Send & Log',
        denyButtonText: `Only log`
      }).then((result) => {
        if (result?.dismiss !== "cancel") {
          const sendReceipt = async () => {
            var refreshed;
            try {
              await access(auth.accessToken);
            } catch (err) {
              refreshed = await refresh();
            } finally {
              try {
                setEmailSent(true);

                const response = await axios.post(
                  "https://api.suzukitree.com/sendReceipt",
                  {
                    userId: refreshed ? refreshed.userId : auth.userId,
                    studentId: student._id,
                    task: result.isConfirmed
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                      Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken
                        }`,
                    },
                  }
                );
                Swal.fire({
                  title: "Sent!",
                  text: response.data.message,
                  icon: response.data.status,
                  confirmButtonText: "Ok!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setValues({
                      subject: "Lessons",
                      body: "",
                    });
                    setRefresh(true);
                    setEmailSent(false);
                  }
                });
              } catch (err) {
                if (err.response.data.status === "error") {
                  Swal.fire({
                    title: "Operation Failed!",
                    text: err.response.data.message,
                    icon: err.response.data.status,
                    confirmButtonText: "Ok!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                    }
                  });
                }
              }
            }
          }

          sendReceipt();
        }
      });
    }
  };

  return !localLoad || tuition ? (
    <div style={{ pointerEvents: emailSent ? "none" : "auto" }}>
      {!emailSent ? <Card sx={{ marginTop: "15px" }}>
        <CardContent>
          <Typography variant="body1">
            <h2>
              <b>{student?.name !== "" ? student.name : "Anonymous"}{active?.length === 2 && active[0] === id ? active[1] === "Invoice" ? "'s Invoice" : active[1] === "Receipt" ? "'s Receipt" : "" : ""}</b>
            </h2>
            <br />
            Date: {moment().format("MM-DD-YYYY")}
            <br />
            <br />
            <b>Name of student: </b>
            {student?.name !== "" ? student.name : "Anonymous"}
            <br />
            <br />
            Suzuki{" "}
            {student?.instruments.length > 1
              ? student.instruments.map(
                (instrument) =>
                  `${instrument} ${student.instruments.length === 0 ? "" : "&"
                  } `
              )
              : student.instruments[0]}{" "}
            {student?.instruments.length > 1 ? "Classes: " : "Class: "}
            {tuition?.lessonPrice && tuition?.lessons
              ? `$${tuition.lessonPrice} / ${tuition.lessons} lessons ($${tuition.lessonPrice * tuition.lessons
              })`
              : "This student doesn't have a lesseon price or lesson amount"}
            <br />
            <br />
            {tuition?.lessons && student?.lessonDays
              ? `Lessons on ${student?.lessonDays ? student.lessonDays : "???"
              } from ${moment(`2000-01-20T${student.startTime}`).format(
                "hh:mm a"
              )} to ${moment(`2000-01-20T${student.endTime}`).format(
                "hh:mm a"
              )} (${tuition.lessons})`
              : "This student doesn't have a lesson day or lesson amount"}
            <br />
            <br />
            {tuition?.rentalInstrument && tuition?.rentalFee
              ? `Renting a ${tuition.rentalInstrument} for $${tuition.rentalFee}`
              : ""}
            {tuition?.rentalInstrument && tuition?.rentalFee ? (
              <>
                <br></br>
                <br />
              </>
            ) : (
              ""
            )}
            {tuition?.specialDesc && tuition?.specialFee
              ? `${tuition.specialDesc} for $${tuition.specialFee}`
              : ""}
            {tuition?.specialDesc && tuition?.specialFee ? (
              <>
                <br></br>
                <br />
              </>
            ) : (
              ""
            )}
            <b>
              Grand Total: $
              {(tuition?.lessons && tuition?.lessonPrice
                ? tuition.lessons * tuition.lessonPrice
                : 0) +
                (tuition?.rentalFee ? tuition.rentalFee : 0) +
                (tuition?.specialFee ? tuition.specialFee : 0)}
            </b>
            <br />
            <br />
            ** Other than emergency or illness, the change has to inform one day
            advance to reschedule the class.
            <br />
            <b>
              Please kindly inform our studio at least one day in advance to
              reschedule the class.
            </b>
            <br />
            <br />
            ** {settings?.businessRef?.businessName} is not responsible for any personal injury,
            accident, or damage to personal property of any people occuring on the
            premises of the studio/house.
            <br />
            <br />
            Thank You for choosing {settings?.businessRef?.businessName}!
            <br />
            <br />
            <b>{settings?.businessRef?.businessName}</b>
            <br />
            <b>{settings?.businessRef?.businessAddress}</b>
            <br />
            <u>{settings?.businessRef?.businessEmail}</u>
            <br />
            <b>{settings?.businessRef?.businessPhoneNumber}</b>
          </Typography>
          {input}
        </CardContent>
        <CardActions>
          <Button size="small" onClick={onClickButton} value={buttonText[0]}>
            {buttonText[0]}
          </Button>
          <Button size="small" onClick={onClickButton} value={buttonText[1]}>
            {buttonText[1]}
          </Button>
        </CardActions>
      </Card> : <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size={100} />
      </Box>}
    </div>
  ) : (
    "Card loading..."
  );
};

export default BillingCard;
