import { useState } from "react";
import { Link as Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import FormHelperText from "@mui/material/FormHelperText";
import PhoneNumberInput from "./PhoneNumberInput";
import { useNavigate } from "react-router-dom";

const initalValues = {
  name: "",
  email: "",
  password: "",
  phoneNumber: "",
  checkbox: "",
};
const validateOnChange = true;

const SignupForm = ({ signup, setEmailSent }) => {
  const [values, setValues] = useState(initalValues);
  const [errors, setErrors] = useState(initalValues);
  const navigate = useNavigate();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : "This field is required.";
    }
    if ("email" in fieldValues) {
      temp.email = fieldValues.email
        ? /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
            fieldValues.email
          )
          ? ""
          : "This email is not valid."
        : "This field is required.";
    }
    if ("password" in fieldValues) {
      temp.password = fieldValues.password
        ? fieldValues.password.length >= 8
          ? ""
          : "Password must be than 8 characters or greater."
        : "This field is required.";
    }
    if ("phoneNumber" in fieldValues) {
      temp.phoneNumber = fieldValues.phoneNumber
        ? fieldValues.phoneNumber.length === 14
          ? ""
          : "This phone number doesn't have 10 digits."
        : "This field is required.";
    }
    if ("checkbox" in fieldValues) {
      temp.checkbox = fieldValues.checkbox
        ? ""
        : "You must argee to our Terms & Conditions and Privacy Policy to signup.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      try {
        setEmailSent(true);
        const response = await signup({
          name: values.name,
          email: values.email,
          password: values.password,
          phoneNumber: values.phoneNumber,
        });

        Swal.fire({
          title: "Registered!",
          text: response.data.message,
          icon: "success",
          confirmButtonText: "Ok!",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/login");
            setEmailSent(false);
          }
        });
      } catch (err) {
        Swal.fire({
          title: "Operation Failed!",
          text: err.response.data.message
            ? err.response.data.message
            : err.response.data,
          icon: "error",
          confirmButtonText: "Ok!",
        });
        console.log(err);
      }
    }
  };

  const onChange = (event) => {
    if (event.target.name === "checkbox") {
      setValues({
        ...values,
        [event.target.name]: event.target.checked,
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    }

    if (validateOnChange) {
      if (event.target.name === "checkbox") {
        validate({ [event.target.name]: event.target.checked });
      } else {
        validate({ [event.target.name]: event.target.value });
      }
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit}
      sx={{ mt: 3 }}
      autoComplete="off"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            onChange={onChange}
            {...(errors.email && { error: true, helperText: errors.email })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={onChange}
            {...(errors.password && {
              error: true,
              helperText: errors.password,
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneNumberInput
            formValues={values}
            setFormValues={setValues}
            errors={errors}
            validate={validate}
            validateOnChange={validateOnChange}
            required
          ></PhoneNumberInput>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            onChange={onChange}
            {...(errors.name && { error: true, helperText: errors.name })}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="checkbox"
                id="checkbox"
                onChange={onChange}
              />
            }
            label="By signing up you are agreeing to our Terms & Conditions and Privacy Policy."
          />
          <FormHelperText error>{errors.checkbox}</FormHelperText>
        </Grid>
      </Grid>
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Sign Up
      </Button>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Redirect to="/login">
            <Link variant="body2">Already have an account? Login</Link>
          </Redirect>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignupForm;
