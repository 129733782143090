import TextField from "@mui/material/TextField";
import SelectFieldV2 from "./SelectFieldV2";
import Grid from "@mui/material/Grid";

const AdvancedSearch = ({ selectValue, setSelectValue, selectInputValue, setSelectInputValue, setSearch, search, values }) => {
  const onChange = (event) => {
    setSelectInputValue(
      event.target.value
    );
  };

  const onKeyDown = (event) => {
    if (event.keyCode == 13) {
      setSearch(!search);
    }
  }

  const grid = {
    '@media (max-width: 780px)' : {
      paddingLeft: '0px',
      paddingRight: '0px',
      width: "150%"
    }
  }

  return (
    <Grid classes={grid} container spacing={1}>
      <Grid item xs={4}>
        <SelectFieldV2
          values={values}
          label="Sort By"
          name="sortBy"
          inputValues={selectValue}
          setInputValues={setSelectValue}
        ></SelectFieldV2>
      </Grid>
      <Grid item xs={8}>
        <TextField
          fullWidth
          id="search"
          name="search"
          label="Search"
          value={selectInputValue}
          onChange={onChange}
          onKeyDown={onKeyDown}
          helperText="Press enter to search"
        ></TextField>
      </Grid>
    </Grid>
  );
};

export default AdvancedSearch;
