import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import SummarizeIcon from "@mui/icons-material/Summarize";

const Footer = () => {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setValue(
      location.pathname === "/" ? "lessons" : location.pathname.slice(1)
    );
    //eslint-disable-next-line
  }, []);

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          navigate(`/${newValue === "lessons" ? "" : newValue}`);
        }}
        sx={{ overflow: "auto" }}
      >
        <BottomNavigationAction
          label="Lessons"
          value="lessons"
          icon={<DashboardIcon />}
        />
        <BottomNavigationAction
          label="Students"
          value="students"
          icon={<PersonIcon />}
        />
        <BottomNavigationAction
          label="Billing"
          value="billing"
          icon={<CreditCardIcon />}
        />
        <BottomNavigationAction
          label="Summary"
          value="summary"
          icon={<SummarizeIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default Footer;
