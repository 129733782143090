import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import PhoneNumberInput from "./PhoneNumberInput";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MultipleSelect from "./MultipleSelect";
import SelectFieldV2 from "./SelectFieldV2";
import useAuth from "../../Hooks/useAuth";
import useRefreshToken from "../../Hooks/useRefreshToken";
// make sure to include parsing for all elements!!!

const initalValues = {
  name: "",
  birthday: "",
  email: "",
  phoneNumber: "",
  instruments: [],
  lessonDays: "Monday",
  startTime: "",
  endTime: "",
  comment: "",
};
const initalErrors = {
  name: "",
  birthday: "",
  email: "",
  phoneNumber: "",
  instruments: [],
  lessonDays: "",
  startTime: "",
  endTime: "",
  comment: "",
};
const validateOnChange = true;

const AddStudent = ({ editId, addState }) => {
  const [values, setValues] = useState(initalValues);
  const [errors, setErrors] = useState(initalErrors);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { auth, access } = useAuth();
  const refresh = useRefreshToken();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      temp.email = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
        fieldValues.email
      )
        ? ""
        : "This email is not valid.";
    }
    if ("phoneNumber" in fieldValues) {
      temp.phoneNumber =
        fieldValues.phoneNumber.length === 14
          ? ""
          : "This phone number doesn't have 10 digits.";
    }
    if ("startTime" in fieldValues) {
      temp.startTime =
        new Date(`2000-01-20T${fieldValues.startTime}`) <
          new Date(`2000-01-20T${fieldValues.endTime}`)
          ? ""
          : "Starting time cannot be greater than ending time";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => !x.length);
    }
  };

  const handleSubmit = async (event, type, editId) => {
    event.preventDefault();
    if (validate()) {
      var refreshed;
      try {
        await access(auth.accessToken);
      } catch (err) {
        refreshed = await refresh();
      } finally {
        try {
          let create;
          let edit;

          if (type === "create") {
            create = await axios.post(
              "https://api.suzukitree.com/addStudent",
              { userId: auth.userId, ...values },
              {
                headers: {
                  "Content-type": "application/json",
                  Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken}`,
                },
              }
            );
          } else if (type === "edit") {
            edit = await axios.post(
              "https://api.suzukitree.com/editStudent",
              { userId: auth.userId, studentId: editId, ...values },
              {
                headers: {
                  "Content-type": "application/json",
                  Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken}`,
                },
              }
            );
          }

          Swal.fire({
            title: type === "create" ? "Added!" : "Edited!",
            text: type === "create" ? create.data.message : edit.data.message,
            icon: type === "create" ? create.data.status : edit.data.status,
            confirmButtonText: "Ok!",
          }).then((result) => {
            if (result.isConfirmed) {
              setValues(initalValues);
              setOptions([]);
            }
          });
        } catch (err) {
          if (err.response.data.status === "error") {
            Swal.fire({
              title: "Operation Failed!",
              text: err.response.data.message,
              icon: err.response.data.status,
              confirmButtonText: "Ok!",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }
        }
      }
    }
  };

  const onChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });

    if (validateOnChange) {
      if (
        event.target.name === "startTime" ||
        event.target.name === "endTime"
      ) {
        validate(
          event.target.name === "startTime"
            ? {
              [event.target.name]: event.target.value,
              endTime: values.endTime,
            }
            : {
              [event.target.name]: event.target.value,
              startTime: values.startTime,
            }
        );
      } else {
        validate({ [event.target.name]: event.target.value });
      }
    }
  };

  const container = {
    '@media (max-width: 780px)': {
      paddingLeft: '0px',
      paddingRight: '0px'
    }
  }

  useEffect(() => {
    setLoading(true);
    if (addState === false && editId !== "") {
      const fetchStudent = async () => {
        var refreshed;
        try {
          try {
            await access(auth.accessToken);
          } catch (err) {
            refreshed = await refresh();
          } finally {
            const res = await axios.post(
              "https://api.suzukitree.com/fetchStudent",
              {
                userId: refreshed ? refreshed.userId : auth.userId,
                studentId: editId,
              },
              {
                headers: {
                  "Content-type": "application/json",
                  Authorization: `Bearer ${refreshed ? refreshed.accessToken : auth.accessToken}`,
                },
              }
            );
            setValues({
              name: res.data[0].name,
              birthday: res.data[0].birthday,
              email: res.data[0].email,
              phoneNumber: res.data[0].phoneNumber,
              instruments: res.data[0].instruments,
              lessonDays: res.data[0].lessonDays,
              startTime: res.data[0].startTime,
              endTime: res.data[0].endTime,
              comment: res.data[0].comment
            });
            setOptions(res.data[0].instruments);
            setLoading(false);
          }
        } catch (err) {
          if (err.response.data.message) {
            console.log(err.response.data.message);
          } else {
            console.log(
              "Something went wrong while to fetch that student's data"
            );
          }
        }
      }
      fetchStudent();
    } else {
      setValues(initalValues);
      setOptions([]);
    }

    //eslint-disable-next-line
  }, [editId, addState]);

  return (typeof editId === "string" && ((addState && editId === "") || ((!addState && editId !== "") && !loading))) ? (
    <Container sx={container}>
      <Box
        id={editId === "" ? "addStudentForm" : "editStudentForm"}
        component="form"
        noValidate
        sx={{ mt: 1 }}
        onSubmit={event => editId === "" ? handleSubmit(event, "create") : handleSubmit(event, "edit", editId)}
        autoComplete="off"
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="name"
              label="Student Name"
              name="name"
              autoFocus
              onChange={onChange}
              value={values.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="birthday"
              label="Birthday"
              name="birthday"
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={onChange}
              value={values.birthday}
              {...(errors.birthday && {
                error: true,
                helperText: errors.birthday,
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              onChange={onChange}
              value={values.email}
              {...(errors.email && { error: true, helperText: errors.email })}
            />
          </Grid>
          <Grid item xs={6}>
            <PhoneNumberInput
              formValues={values}
              setFormValues={setValues}
              errors={errors}
              validate={validate}
              validateOnChange={validateOnChange}
            ></PhoneNumberInput>
          </Grid>
          <Grid item xs={6}>
            <MultipleSelect
              id="instruments"
              label="Instruments"
              items={[
                "Violin",
                "Viola",
                "Cello",
                "Piano",
                "Flute",
                "Guitar",
                "Basse",
                "Drums",
              ]}
              values={values}
              setValues={setValues}
              options={options}
              setOptions={setOptions}
            ></MultipleSelect>
          </Grid>
          <Grid item xs={6}>
            <SelectFieldV2
              values={[
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ]}
              label="Lesson Days"
              name="lessonDays"
              inputValues={values}
              setInputValues={setValues}
            ></SelectFieldV2>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="startTime"
              label="Starting Time"
              name="startTime"
              type="time"
              InputLabelProps={{ shrink: true }}
              onChange={onChange}
              {...(errors.startTime && {
                error: true,
                helperText: errors.startTime,
              })}
              value={values.startTime}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="endTime"
              label="Ending Time"
              name="endTime"
              type="time"
              InputLabelProps={{ shrink: true }}
              onChange={onChange}
              value={values.endTime}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="comment"
              label="Comment"
              name="comment"
              multiline
              onChange={onChange}
              value={values.comment}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 8 }}
            >
              {editId === "" ? "Create student" : "Edit student"}

            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  ) : "";
};

export default AddStudent;
