import { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const PhoneNumberInput = ({
  formValues,
  setFormValues,
  errors,
  validate,
  validateOnChange,
  required,
}) => {
  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
    if (validateOnChange) {
      validate({ [event.target.name]: event.target.value });
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        htmlFor="phoneNumber"
        {...(errors.phoneNumber && { error: true })}
      >
        Phone Number {required ? "*" : ""}
      </InputLabel>
      <OutlinedInput
        required
        value={formValues.phoneNumber}
        name="phoneNumber"
        id="phoneNumber"
        inputComponent={TextMaskCustom}
        onChange={handleChange}
        label="Phone Number"
        {...(errors.phoneNumber && { error: true })}
      />
      <FormHelperText error>{errors.phoneNumber}</FormHelperText>
    </FormControl>
  );
};

export default PhoneNumberInput;
